import React from 'react'
import Helmet from 'react-helmet'
import Page from '../../components/ui/Page'
import SplitContainer from '../../components/ui/SplitContainer'
import sideimage from './jordon-conner-unsplash.jpg'
import styled from '@emotion/styled'
import Theme from '../../utils/theme'
import Layout from '../../components/Layout'

const { Title, Paragraph: P } = SplitContainer

const A = styled.a`
text-decoration: none;
color: ${Theme.colors.primaryFont};
font-weight: 500;
font-size: 16px;
padding: 0px 0px 0px 0px;
display: inline-block
cursor: pointer;
&:hover {color: ${Theme.colors.selection};
}

`

const Bold = styled.span`
  font-weight: bold;
`

const PartnershipPage = () => (
  <Layout>
    <Page>
      <SplitContainer imageUrl={sideimage}>
        <Helmet title={`Partner with Park City Insider`} />
        <Title>Work With Us</Title>

        <P>
          We're a small team working towards unifying Park City by inviting
          everyone to the party. This area is filled with outstanding people,
          one-of-a-kind venues and of course the beautiful mountains. We
          understand that we live in a gem of a city and want to share it
          responsibly.
        </P>
        <P>
          If you want to work with us towards making Park City more unified
          please contact us and let us know. If you want more exposure on an
          event, advertise a business to other Insiders or have other plans we
          want to hear about it.
        </P>
        <P>
          Let us know what you are looking for and how we can work together to
          accommodate your needs best. We look forward to hearing from you.
        </P>
        <P>
          <Bold>
            Contact Jay <br />
          </Bold>
          <A href="tel:14355570094">1-435-557-0094 </A> <br />
          <A href="mailto:jay@parkcityinsider.com">jay@parkcityinsider.com </A>
        </P>
      </SplitContainer>
    </Page>
  </Layout>
)

export default PartnershipPage
